<template>
  <section>
    <validation-observer v-if="!isStripeError" ref="cardObserver" slim>
      <div :class="{...rowCSSClass,'card-panel':true}" >
          <label for="cardNumber" :class="labelCSSClass">{{ label }}</label>
          <div :class="fieldCSSClass">
            <div class="row card-number">
              <div class="col">
                <validation-provider ref="cardNumber" :name="id+'_cardNumber'" mode="eager" :rules="cardNumberValidationRules" v-slot="{ errors, classes }" slim>
                  <div :id="id+'_cardNumber'" :class="{...inputCSSClass, 'required':required, ...classes}"></div>
                  <span :class="{...controlCSSClass}" style="display:block;" v-if="errors.length > 0">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
            <div class="row card-expiry card-cvc">
              <div class="col-sm">
                <validation-provider ref="cardExpiry" :name="id+'_cardExpiry'" mode="eager" :rules="cardExpiryValidationRules" v-slot="{ errors, classes }" slim>
                  <div :id="id+'_cardExpiry'" :class="{...inputCSSClass, 'required':required, ...classes}"></div>
                  <span :class="{...controlCSSClass}" style="display:block;" v-if="errors.length > 0">{{ errors[0] }}</span>
                </validation-provider>
              </div>
              <div class="col-sm">
                <validation-provider ref="cardCvc" :name="id+'_cardCvc'" mode="eager" :rules="cardCvcValidationRules" v-slot="{ errors, classes }" slim>
                  <div :id="id+'_cardCvc'" :class="{...inputCSSClass, 'required':required, ...classes}"></div>
                  <span :class="{...controlCSSClass}" style="display:block;" v-if="errors.length > 0">{{ errors[0] }}</span>
                </validation-provider>
              </div>
            </div>
          </div>    
      </div>
    </validation-observer>
  </section>
</template>

<style>

.card-panel .card-number {
  margin-bottom:15px;
}

@media (max-width: 575.98px) {
  .card-panel .card-expiry.card-cvc > div {
    margin-bottom:15px;
  }
}


</style>

<script lang="ts">
import { Component, Prop, Watch } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';
import { languagesTypes } from '@fwk-client/store/types';
import { extend } from "vee-validate";
import { Stripe, StripeElements, StripeCardNumberElement, StripeCardExpiryElement, StripeCardCvcElement, StripeCardNumberElementChangeEvent, StripeCardExpiryElementChangeEvent, StripeCardCvcElementChangeEvent } from '@stripe/stripe-js';


@Component({
  components: {}
})
export default class Card extends mixins<GenericInput<string>>(GenericInput) {

  @Prop({
    type: Object,
    required: false,
  }) readonly options!: any | undefined

  computedOptions:any = {
    ...this.options
  };

  stripe:Stripe|undefined;
  elements:StripeElements|undefined;
  cardNumber:StripeCardNumberElement|undefined;
  cardExpiry:StripeCardExpiryElement|undefined;
  cardCvc:StripeCardCvcElement|undefined;

  isStripeError:boolean = false;

  input = {}

  created() {
    this.addCardNumberValidation();
    this.addCardExpiryValidation();
    this.addCardCvcValidation();
  }

  mounted() {
    var stripe = this.$shop.payment.getStripeInstance()
    .then((stripe:Stripe) => {
      this.stripe = stripe;
      this.initializecCardFields();
    })
    .catch((error) => {
      this.isStripeError = true;
      this.$emit('stripe-error');
    });
  }

  initializecCardFields() {
    if(this.$refs.cardObserver) {
            // @ts-ignore
            this.$refs.cardObserver.reset();
    }
    if(this.stripe && this.id) {
      var locale = this.$store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];
      this.$shop.payment.createStripeCardElements(this.id, locale)
      .then((cardElements:any) => {
        cardElements.cardNumber.mount('#' + this.id + '_cardNumber');
        cardElements.cardExpiry.mount('#' + this.id + '_cardExpiry');
        cardElements.cardCvc.mount('#' + this.id + '_cardCvc');

        var componentInstance = this;
        cardElements.cardNumber.on('change', function(event:Event) {
          // @ts-ignore
          componentInstance.$refs.cardNumber.validate(event);
          componentInstance.validateCardForm();
        });
        cardElements.cardExpiry.on('change', function(event:Event) {
          // @ts-ignore
          componentInstance.$refs.cardExpiry.validate(event);
          componentInstance.validateCardForm();
        });
        cardElements.cardCvc.on('change', function(event:Event) {
          // @ts-ignore
          componentInstance.$refs.cardCvc.validate(event);
          componentInstance.validateCardForm();
        });
      })
      .catch((error) => {
        console.log("CARD - CREATE STRIPE ELEMENTS");
        console.log(error);
        this.isStripeError = true;
        this.$emit('stripe-error');
      });
    }
  }

  get cardNumberValidationRules() {
    var validation:any = {
      stripeCardNumber : {
        field : this.cardNumber
      }
    }
    if(this.required) {
      validation = {
        ...validation,
        "required" : true
      }
    }
    return validation;
  }

  addCardNumberValidation() {
    var componentInstance = this;
    extend('stripeCardNumber',{
      params: ['field'],
      validate(event:StripeCardNumberElementChangeEvent, params:any):Promise<boolean|string> {

        if(event.empty && componentInstance.required) {
          return Promise.resolve(componentInstance.$t("error.fields.required") as string);
        }
        if(event.error) {
          return Promise.resolve(event.error.message);
        }
        if(event.complete) {
          return Promise.resolve(true);
        }
        
        return Promise.resolve(true);
      }
    });
  }

  get cardExpiryValidationRules() {
    var validation:any = {
      stripeCardExpiry : {
        field : this.cardExpiry
      }
    }
    if(this.required) {
      validation = {
        ...validation,
        "required" : true
      }
    }
    return validation;
  }

  addCardExpiryValidation() {
    var componentInstance = this;
    extend('stripeCardExpiry',{
      params: ['field'],
      validate(event:StripeCardExpiryElementChangeEvent, params:any):Promise<boolean|string> {

        if(event.empty && componentInstance.required) {
          return Promise.resolve(componentInstance.$t("error.fields.required") as string);
        }
        if(event.error) {
          return Promise.resolve(event.error.message);
        }
        if(event.complete) {
          return Promise.resolve(true);
        }
        
        return Promise.resolve(true);
      }
    });
  }

  get cardCvcValidationRules() {
    var validation:any = {
      stripeCardCvc : {
        field : this.cardExpiry
      }
    }
    if(this.required) {
      validation = {
        ...validation,
        "required" : true
      }
    }
    return validation;
  }

  addCardCvcValidation() {
    var componentInstance = this;
    extend('stripeCardCvc',{
      params: ['field'],
      validate(event:StripeCardCvcElementChangeEvent, params:any):Promise<boolean|string> {

        if(event.empty && componentInstance.required) {
          return Promise.resolve(componentInstance.$t("error.fields.required") as string);
        }
        if(event.error) {
          return Promise.resolve(event.error.message);
        }
        if(event.complete) {
          return Promise.resolve(true);
        }
        
        return Promise.resolve(true);
      }
    });
  }

  validateCardForm() {
    if(this.$refs.cardObserver) {
            // @ts-ignore
            this.$refs.cardObserver.validate({silent:true}).then((isValid:boolean) => {
                this.input = isValid;
            })
        }
  }

  @Watch('$store.state.languages.currentLanguageCode')
  onLanguageChange(to:any, from:any) {
    this.initializecCardFields();
  }
  
  
}
</script>