<template>
    <div :class="'row payment-item payment-'+payment.type.toLowerCase()">
        <div class="col-md-auto">
            <Radio 
                class="p-default p-round p-pulse" 
                color="success-o" 
                name="payment" 
                :checked="isSelected" 
                :value="index" 
                :disabled="isDisabled" 
                v-on:change="onChange"
                :label="$shop.payment.getPaymentTitle(payment)">
            </Radio> 
        </div>
        <div class="col-md" v-if="payment.type == 'CARD'">
            <div class="alert alert-warning" v-if="payment.isTest">
                {{ $t("shop.payments.checkout.test") }}
            </div>
            <div class="alert alert-danger" v-if="isPaymentError">
                {{ $t("shop.payments.checkout.stripe-error") }}
            </div>
            <Card 
                v-bind:value.sync="isValid" 
                id="checkout_card"
                :inputClass="{'form-control':true, 'sm-form-control':true}" 
                :rowClass="{'col_full':true}"
                :label="$t('shop.payments.card.label')"
                v-on:stripe-error="onPaymentError"
                required
            />

            <div class="logo">
                <img src="@public/img/vigneron-online/online-security.gif" alt="Verified by Visa, Mastercard Securecode">
                <a class="stripe" href="https://stripe.com/" target="_blank">
                    <img src="@public/img/vigneron-online/powered_by_stripe@2x.png" alt="Powered by Stripe">
                </a>
            </div>
            
        </div>
        <div class="col-md" v-else-if="payment.type == 'PAY_LATER'">
            <div class="note" v-if="payment.note" v-html="getLocalizedText(payment.note)">
            </div>
        </div>
    </div>
               
</template>

<style>

.payment-item.row {
    margin-left:0px;
    margin-right:0px;
}

.payment-item:first-child > div {
    border:0px;
}

.payment-item > div {
    border-top: 2px solid #dee2e6;
    padding: 0.75rem;
}

@media (max-width: 767.98px) {
    .payment-item > div:last-child {
        border-top:0px;
    }
}

.payment-card .col_full.card-panel {
    margin-bottom: 20px!important;
}

.payment-card .logo {
    display:block;
    margin-left: auto;
    height: 35px;
    float: right;
}

.payment-card .logo img {
    height: 35px;
}

.payment-card .logo img:not(:last-child) {
    margin-right:10px;
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import Card from '@fwk-client/components/panels/input/Card.vue';
import Radio from '@fwk-client/components/panels/input/pretty-checkbox/Radio.vue';

@Component({
    components: {
        Card,
        Radio
    },
    computed: {},
})
export default class PaymentItem extends Vue {

    @Prop({
        type: Number,
        required: false
    }) readonly value!: number|null

    @Prop({
        type: Object,
        required: true
    }) readonly payment: any

    @Prop({
        type: Number,
        required: true
    }) readonly index!: number

    input = this.index;
    isValid:boolean = false;
    isPaymentError:boolean = false;

    get isSelected() {
        return this.value == this.index && !this.isDisabled
    }

    get isDisabled() {
        return false
    }

    onChange(val:number) {
        this.$emit('update:value',val);
    }

    onPaymentError() {
        this.isPaymentError = true;
    }

    @Watch('isSelected') 
    onSelectedChange(val: any, oldVal: any) {
        if(val == false && oldVal == true && this.isDisabled) {
            this.$emit('update:value',null);
        }
    }

    @Watch('isValid')
    onValidate(val:any, oldVal:any) {
        this.$emit('onValidate', this.index, val);
    }
}

</script>