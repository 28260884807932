<template>
  <div class="pretty">
    <input type="checkbox" :name="id" :checked="checked" :disabled="disabled" :value="value" @change="$emit('change', $event.target.checked)">
    <div :class="stateCssClass">
      <label>
        {{ inputLabel }}
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { Component , Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '../../../mixins/GenericInput.vue';

@Component({
  components : {}
})
export default class Checkbox extends mixins<GenericInput<any>>(GenericInput) {

  @Prop({
    type: Boolean,
    required: false
  }) readonly checked!: boolean

  @Prop({
    type: Boolean,
    required: false
  }) readonly disabled!: boolean

  @Prop({
    type: String,
    required: false
  }) readonly color!: string

  get inputLabel() {
    return (this.label && this.label != "") ? this.label : "";
  }

  get stateCssClass() {
    if(this.color && this.color != "") {
      return "state p-"+this.color;
    }
    return "state"
  }

  @Watch('input', { deep: true })
  onInputChange(val: any, oldVal: any) {
    this.$emit('update:checked', val);
  }
  
}
</script>