<template>
    
    <div ref="paymentEl" class="payment">
      <div class="alert alert-danger" v-if="showMandatoryWarning">
            {{ labels.mandatoryWarning }}
		  </div>
      <div class="alert alert-danger" v-if="methodsOfPayment.length == 0">
            {{ labels.noMethodAllowed }}
		  </div>
      <div class="alert alert-danger" v-if="paymentError != null">
            {{ paymentError }}
		  </div>
      <div v-if="methodsOfPayment.length > 0">
        <PromoCodes v-if="$shop_isPromoCodesAllowed" />
        <PaymentItem v-bind:value.sync="paymentIndex" v-for="(payment, index) in methodsOfPayment" :key="index + '-' + payment.type" :payment="payment" :index="index" @onValidate="onPaymentValidate"></PaymentItem>
        <div class="row conditions" v-if="!computedOptions.disableTermsAndConditions">
          <div class="col">
            <Checkbox 
                class="p-default p-pulse" 
                color="success-o" 
                name="conditions" 
                :checked="conditionsAccepted"
                v-on:change="(val) => { conditionsAccepted = val }">
            </Checkbox> 
            <span v-html="labels.termsAndConditions"></span>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col-lg-6 clearfix">
            <div class="table-responsive">
              <table class="table cart-recap amounts">
                <tbody>
                  <tr class="cart_item" v-if="showSubTotal">
                    <td class="cart-product-name">
                      <strong>{{ labels.subTotal }}</strong>
                    </td>

                    <td class="cart-product-name">
                      <span class="amount">{{ formatPriceAmount(subTotal) }}</span>
                    </td>
                  </tr>
                  <tr class="cart_item" v-if="showDeliveryPrice">
                      <td class="cart-product-name">
                          <strong>{{ labels.deliveryPrice }}</strong>
                      </td>
                      <td class="cart-product-name">
                          <span class="amount">{{ formatPriceAmount(deliveryPrice) }} </span>
                      </td>
                  </tr>
                  <tr class="cart_item" v-if="promoCodes.length > 0">
                      <td class="cart-product-name">
                          <strong>{{ labels.promoCodesReduction }}</strong>
                      </td>
                      <td class="cart-product-name">
                          <span class="amount">- {{ formatPriceAmount(promoCodesReduction) }} </span>
                      </td>
                  </tr>
                  <tr class="cart_item">
                    <td class="cart-product-name">
                      <span class="amount color lead"><strong>{{ labels.totalPrice }}</strong></span>
                    </td>

                    <td class="cart-product-name">
                      <span class="amount color lead"><strong>{{ formatPriceAmount(totalPrice) }}</strong></span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="alert alert-warning" v-if="!isPaymentAllowed">
              <div v-if="!isContactValid">
                {{ labels.contactInvalidWarning }}
              </div>
              <div v-if="!isDeliveryValid">
                {{ labels.deliveryMandatoryWarning }}
              </div>
              <div v-if="!isPaymentValid">
                {{ labels.paymentInvalidWarning }}
              </div>
              <div v-if="!conditionsAccepted">
                {{ labels.conditionsMandatoryWarning }}
              </div>
            </div>
            <button :disabled="!isPaymentAllowed" class="button payment button-3d ladda-button m-0 mb-3" data-style="zoom-in" @click="onPaymentClick">{{ labels.checkoutButton }}</button>
          </div>
        </div>
      </div>
    </div>
       
</template>

<style>
.payment .conditions {
  margin-top:30px;
  margin-bottom: 20px;
}

.payment .conditions .pretty {
  display:inline-block;
  width: 20px;
}

.button.payment {
  width: 100%;
}

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, Ref, toRefs, watch } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { languagesTypes } from '@fwk-client/store/types';
import PaymentItem from './PaymentItem.vue';
import PromoCodes from './PromoCodes.vue';
import * as Ladda from 'ladda';

import Checkbox from '@fwk-client/components/panels/input/pretty-checkbox/Checkbox.vue';
import { useCart } from '@root/src/client/composables/useCart'



/** @cmsOptions */
export interface PaymentOptions {
    /** @cmsType CmsBoolean */
    disableTermsAndConditions?:CmsBoolean;
}

/** @cmsLabels */
export interface PaymentLabels {
  /** @cmsType CmsLabel */
  mandatoryWarning?:CmsLabel;
  /** @cmsType CmsLabel */
  noMethodAllowed?:CmsLabel;
  /** @cmsType CmsContent */
  termsAndConditions?:CmsContent;
  /** @cmsType CmsLabel */
  subTotal?:CmsLabel;
  /** @cmsType CmsLabel */
  deliveryPrice?:CmsLabel;
  /** @cmsType CmsLabel */
  promoCodesReduction?:CmsLabel;
  /** @cmsType CmsLabel */
  totalPrice?:CmsLabel;
  /** @cmsType CmsLabel */
  contactInvalidWarning?:CmsLabel;
  /** @cmsType CmsLabel */
  deliveryMandatoryWarning?:CmsLabel;
  /** @cmsType CmsLabel */
  paymentInvalidWarning?:CmsLabel;
  /** @cmsType CmsLabel */
  conditionsMandatoryWarning?:CmsLabel;
  /** @cmsType CmsLabel */
  checkoutButton?:CmsLabel;
}

/** @cmsSlots */
export interface PaymentSlots {
    
}

export default defineComponent({
  props: {
      options: Object as PropType<PaymentOptions>,
      labels: {
        type: Object as PropType<PaymentLabels>,
        default: () => { return {} }
      },
      components: Object as PropType<PaymentSlots>,
      type: {
        type: String as PropType<"shop"|"hospitality">,
        default: "shop",
        required: false
      },
      isDeliveryValid: {
        type: Boolean as PropType<boolean>,
        default: false,
        required: true
      },
      isContactValid: {
        type: Boolean as PropType<boolean>,
        default: false,
        required: true
      }
  },
  components: {
      PaymentItem,
      PromoCodes,
      Checkbox
  },
  setup(props, context) {
    const app = getApp();
    const $store = useStore();

    const computedOptions:PaymentOptions = {
        disableTermsAndConditions : false,
        ...props.options,
    };

    const { cartProducts, cartActivities, subTotal, deliveryPrice, promoCodes, promoCodesReduction, totalPrice, methodsOfPayment, selectMethodOfPayment, onPaymentClick } = useCart({
            type: props.type
        }, context);

    const labels:PaymentLabels = {
        mandatoryWarning: props.type == "shop" ? app.$t("shop.payments.checkout.mandatory-warning") : app.$t("hospitality.payments.checkout.mandatory-warning"),
        noMethodAllowed: props.type == "shop" ? app.$t("shop.payments.checkout.no-method-allowed") : app.$t("hospitality.payments.checkout.no-method-allowed"),
        termsAndConditions: props.type == "shop" ? app.$t('shop.checkout.terms-and-conditions') : app.$t("hospitality.checkout.terms-and-conditions"),
        subTotal: props.type == "shop" ? app.$t("shop.checkout.review-cart.sub-total") : app.$t("hospitality.checkout.review-cart.sub-total"),
        deliveryPrice: props.type == "shop" ? app.$t("shop.deliveries.checkout.sub-total") : undefined,
        promoCodesReduction: props.type == "shop" ? app.$t("shop.payments.promoCodes.total") : app.$t("hospitality.payments.promoCodes.total"),
        totalPrice: props.type == "shop" ? app.$t("shop.payments.total") : app.$t("hospitality.payments.total"),
        contactInvalidWarning: props.type == "shop" ? app.$t("shop.checkout.contact.invalid-warning") : app.$t("hospitality.checkout.contact.invalid-warning"),
        deliveryMandatoryWarning: props.type == "shop" ? app.$t("shop.deliveries.checkout.mandatory-warning") : app.$t("hospitality.deliveries.checkout.mandatory-warning"),
        paymentInvalidWarning: props.type == "shop" ? app.$t("shop.payments.checkout.invalid-warning") : app.$t("hospitality.payments.checkout.invalid-warning"),
        conditionsMandatoryWarning: props.type == "shop" ? app.$t("shop.checkout.mandatory-conditions-warning") : app.$t("hospitality.checkout.mandatory-conditions-warning"),
        checkoutButton: props.type == "shop" ? app.$t("shop.checkout.button") : app.$t("hospitality.checkout.button"),
        ...props.labels
    }

    const { isDeliveryValid, isContactValid } = toRefs(props);

    const currentLanguageCode =  $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

    const paymentIndex:Ref<number|null> = ref(0); // We select the first method of payment by default
    const showMandatoryWarning:Ref<boolean> = ref(false);
    const paymentValid:Ref<boolean[]> = ref([]);
    const paymentError:Ref<string|null> = ref(null);
    const conditionsAccepted:Ref<boolean> = ref(false);

    const paymentEl:Ref<HTMLElement|null> = ref(null);

    var laddaSubmit:Ladda.LaddaButton|null = null;

    // We initialize the payment validation state
    paymentValid.value = methodsOfPayment.value.map((payment:any) => {
      return false;
    })
    // We initialize the store with default method of payment
    if(methodsOfPayment.value.length > 0) {
      selectMethodOfPayment(paymentIndex.value);
    }

    // We update conditions accepted if disabled
    if(computedOptions.disableTermsAndConditions) {
      conditionsAccepted.value = true;
    }

    const showSubTotal:Ref<boolean> = computed(() => {
      if(props.type == "hospitality" || cartProducts.value.length == 0) {
        return false;
      }
      return true;
    });
    const showDeliveryPrice:Ref<boolean> = computed(() => {
      if(props.type == "hospitality" || cartProducts.value.length == 0) {
        return false;
      }
      return true;
    })

    onMounted(() => {
      if(methodsOfPayment.value.length > 0) {
        // @ts-ignore   
        var submitButton:HTMLButtonElement|null = paymentEl.value.querySelector( 'button.payment.ladda-button' );
        laddaSubmit = Ladda.create(submitButton!);
      }
    })

    const onPaymentButtonClick = (event:Event) => {

      laddaSubmit!.start();
      paymentError.value = null;
      
      onPaymentClick(event)
      .then(() => {
       
      })
      .catch((error:any) => {
        // We display an error to the end user
        paymentError.value = error;
        laddaSubmit!.stop();
      });
      
    }

    const onPaymentValidate = (index:number, isValid:boolean) => {
      paymentValid.value.splice(index, 1, isValid);
    }

    const isPaymentValid = computed(() => {
      var isPaymentValid = methodsOfPayment.value && methodsOfPayment.value.length > 0 && paymentIndex.value != null;
      if(isPaymentValid && methodsOfPayment.value[paymentIndex.value!].type == 'CARD') {
        // We check if the card field if complete
        isPaymentValid = isPaymentValid && paymentValid.value[paymentIndex.value!];
      }

      return isPaymentValid;
    })

    const isPaymentAllowed = computed(() => {
      return isContactValid.value && isDeliveryValid.value && isPaymentValid.value && conditionsAccepted.value;
    })

    watch(
      paymentIndex,
      (val:any, oldVal:any) => {
          // We update the shop store
        selectMethodOfPayment(val);
        // We check if we need to show a warning
        if(val == null && oldVal != null) {
            // We show a warning
            showMandatoryWarning.value = true;
        }
        else {
            // We hide the warning
            showMandatoryWarning.value = false;
        }
      }
    )

    return {
        paymentEl,
        labels,
        currentLanguageCode,
        computedOptions: {
          ...computedOptions
        },
        showMandatoryWarning,
        paymentError,
        paymentIndex,
        conditionsAccepted,
        onPaymentValidate,
        isPaymentAllowed,
        isContactValid,
        isDeliveryValid,
        isPaymentValid,
        onPaymentClick:onPaymentButtonClick,
        subTotal,
        deliveryPrice,
        totalPrice,
        promoCodes,
        promoCodesReduction,
        methodsOfPayment,
        showSubTotal,
        showDeliveryPrice
    }

  }
})
</script>