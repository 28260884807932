<template>
    <div v-if="computedOptions.section" :class="'section' + (computedOptions.noMargin ? ' mt-0 mb-0' : '') + (computedOptions.noPadding ? ' pt-0 pb-0' : '')">
        <div v-if="computedOptions.container" class="container clearix">
          <slot/>
        </div>
        <slot v-else />
    </div>
    <div v-else-if="computedOptions.contentWrap" :class="'content-wrap' + (computedOptions.noMargin ? ' mt-0 mb-0' : '') + (computedOptions.noPadding ? ' pt-0 pb-0' : '')">
      <div v-if="computedOptions.container" class="container clearix">
          <slot/>
        </div>
        <slot v-else />
    </div>
    <div v-else-if="computedOptions.container" :class="'container clearix' + (computedOptions.noMargin ? ' mt-0 mb-0' : '') + (computedOptions.noPadding ? ' pt-0 pb-0' : '')">
      <slot />
    </div>
    <div v-else :class="(computedOptions.noMargin ? ' mt-0 mb-0' : '') + (computedOptions.noPadding ? ' pt-0 pb-0' : '')">
      <slot  />
    </div>
</template>

<script lang="ts">
import { CmsEnum, CmsBoolean } from '@fwk-client/modules/cms/types';
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';

/** @cmsOptions */
export interface SectionOptions {
  /** @cmsType CmsBoolean */
  section?:CmsBoolean,
  /** @cmsType CmsBoolean */
  contentWrap?:CmsBoolean,
  /** @cmsType CmsBoolean */
  container?:CmsBoolean,
  /** @cmsType CmsBoolean */
  noMargin?:CmsBoolean
  /** @cmsType CmsBoolean */
  noPadding?:CmsBoolean
}

@Component({
  components: { 
  },
  // We add name property from store as a name computed
  computed: {}
})
export default class Section extends Vue {

  @Prop({
      type: Object,
      required: false,
  }) readonly options!: SectionOptions | undefined

  computedOptions:SectionOptions = {
    section:true,
    contentWrap:false,
    container:true,
    noMargin:true,
    noPadding:false,
      ...this.options
  };

}
</script>