<template>
    
    <Section :options="computedOptions.sectionOptions" class="checkout">

      <p class="back-to-apartments">
        <router-link :to="{ name : 'hospitality-apartments', params : { lang : currentLanguageCode }}">
          <i class="icon-angle-left1"></i>
          {{ getLocalizedText(labels.backToApartments) }}
        </router-link>
      </p>

      <div v-if="$hospitality_cartProducts.length > 0" class="steps">
        <div class="row step">

          <div>
            <ul class="process-steps">
              <li class="ui-tabs-active">
                <span class="i-circled i-bordered mx-auto ui-tabs-anchor">1</span>
                <h5>{{ getLocalizedText(labels.reviewCartStep) }}</h5>
              </li>
            </ul>
          </div>

          <div class="col">
            <Cart :options="{type:'hospitality'}"></Cart>
          </div>

        </div>

        <div class="row step">

          <div>
            <ul class="process-steps">
              <li class="ui-tabs-active">
                <span class="i-circled i-bordered mx-auto ui-tabs-anchor">2</span>
                <h5>{{ getLocalizedText(labels.paymentStep) }}</h5>
              </li>
            </ul>
          </div>

          <div class="col payment-step">
            <Payment 
              :isContactValid="true" 
              :isDeliveryValid="true"
              type="hospitality"
              :options="computedOptions.paymentOptions"
            ></Payment>
          </div>

        </div>

      </div>

      <p v-if="$hospitality_cartProducts.length == 0">
        {{ getLocalizedText(labels.emptyCart) }}
      </p>

      <p class="back-to-apartments">
        <router-link :to="{ name : 'hospitality-apartments', params : { lang : currentLanguageCode }}">
          <i class="icon-angle-left1"></i>
          {{ getLocalizedText(labels.backToApartments) }}
        </router-link>
      </p>

    </Section>

</template>


<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { languagesTypes } from '@fwk-client/store/types';
import Cart from '@root/src/client/components/panels/shop/checkout/Cart.vue';
import Payment, { PaymentOptions } from '@root/src/client/components/panels/shop/checkout/Payment.vue';


/** @cmsOptions */
export interface CheckoutOptions {
  /** @cmsType CmsInterface */
  sectionOptions?:SectionOptions
  /** @cmsType CmsInterface */
  paymentOptions?:PaymentOptions
}

/** @cmsLabels */
export interface CheckoutLabels {
  /** @cmsType CmsLabel */
  backToApartments?:CmsLabel;
  /** @cmsType CmsLabel */
  emptyCart?:CmsLabel;
  /** @cmsType CmsLabel */
  reviewCartStep?:CmsLabel;
  /** @cmsType CmsLabel */
  paymentStep?:CmsLabel;
}

export const defaultLabels:CheckoutLabels = {
    paymentStep : {
        fr : "Paiement",
        de : "Zahlung",
        en : "Payment"
    }
}

/** @cmsSlots */
export interface CheckoutSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<CheckoutOptions>,
        labels: {
          type: Object as PropType<CheckoutLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<CheckoutSlots>
    },
    components: {
        Section,
        Cart,
        Payment
    },
    setup(props, context) {
        const app = getApp();
        const $store = useStore();

        const computedOptions:CheckoutOptions = {
            sectionOptions : {
                section:false
            },
            ...props.options,
        };

        const labels:CheckoutLabels = {
            backToApartments: app.$t("hospitality.back-to-apartments"),
            emptyCart: app.$t("hospitality.checkout.empty-cart"),
            reviewCartStep: app.$t("hospitality.checkout.review-cart.step"),
            ...defaultLabels,
            ...props.labels
        }

        const currentLanguageCode =  $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

        app.$hospitality.cart.startCheckout();

        return {
            labels,
            currentLanguageCode,
            computedOptions: {
              ...computedOptions
            }
        }

    }
})
</script>