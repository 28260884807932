<template>
    <div class="promo-codes" ref="promoCodes">
        <div class="row" >
            <div class="col-md-auto col-form-label">
                <label for="promoCodeInput">{{ $t("shop.payments.promoCodes.label") }}</label>
            </div>
            <div class="col-md">
                <input id="promoCodeInput" type="text" v-model="input" class="form-control sm-form-control" :placeholder="$t('shop.payments.promoCodes.input_placeholder')" />
            </div>
            <div class="col-md-auto">
                <button :disabled="input == ''" class="button add-promo button-3d ladda-button m-0" data-style="zoom-in" type="button" @click="addPromoCode">
                    {{ $t("shop.payments.promoCodes.button") }}
                </button>
            </div>
        </div>
        <div class="row applied-codes" v-if="$shop_cartPromoCodes.length > 0">
            <div class="col-md-auto">
                {{ $t("shop.payments.promoCodes.list") }}
            </div>
            <div class="col">
                <div v-for="(promoCode, index) in $shop_cartPromoCodes" :key="index + '-' + promoCode.code">
                    <span class="code">{{promoCode.code}}</span>
                    <span class="note" v-if="promoCode.note">{{ getLocalizedText(promoCode.note) }}</span>
                </div>
            </div>
        </div>
    </div>
               
</template>

<style>

 .promo-codes {
     margin-bottom: 20px;
 }

 .promo-codes .applied-codes {
     margin-top: 20px;
 }

 .promo-codes .applied-codes span.code {
    font-weight: 600;
    color: #28a745
 }

 .promo-codes .applied-codes span.note {
     margin-left:10px;
 }

@media (max-width: 767.98px) {
    .promo-codes .button.add-promo{
        margin-top: 10px!important;
    }
}

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop, Watch} from '@fwk-node-modules/vue-property-decorator';
import * as Ladda from 'ladda';

@Component({
    components: {
    },
    computed: {},
})
export default class PromoCodes extends Vue {

    input = "";

    laddaSubmit:Ladda.LaddaButton|null = null;

    mounted() {
        // @ts-ignore   
        var addPromoCodesButton:HTMLButtonElement|null = this.$refs.promoCodes.querySelector( 'button.add-promo.ladda-button' );
        this.laddaSubmit = Ladda.create(addPromoCodesButton!);
    }

    addPromoCode(evt:Event) {
        evt.preventDefault();

        this.laddaSubmit!.start();

        this.$shop.cart.addPromoCode(this.input).then((response:any) => {
            if(response.added) {
                
            }
            this.laddaSubmit!.stop();
        });

    }
    
    
}

</script>