<template>
    <div>
        <Products v-if="computedOptions.type == 'shop' && cartProducts.length > 0" :products="cartProducts"></Products>
        <Activities v-if="computedOptions.type == 'shop' && cartActivities.length > 0" :products="cartActivities"></Activities>
        <Rentals v-if="computedOptions.type == 'hospitality'" :options="{products: cartProducts}"></Rentals>
        <Validation v-if="computedOptions.type == 'hospitality'" :options="{products: cartProducts}"></Validation>
        <div class="row">
            <div class="col"></div>
            <div class="col-auto clearfix">
                <div class="table-responsive">
                    <table class="table cart-recap amounts">
                        <tbody>
                            <tr class="cart_item">
                                <td class="cart-product-name">
                                    <strong>{{ labels.subTotal }}</strong>
                                </td>
                                <td class="cart-product-name">
                                    <span class="amount">{{ formatPriceAmount(subTotal) }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style>

    .amounts .cart-product-name {
        text-transform: uppercase;
    }


    /* We remove the line below all amounts in recap of checkout page */
    .amounts tr:last-child .cart-product-name {
        border-bottom-width: 0px;
    }

</style>

<script lang="ts">
import { defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref } from '@fwk-node-modules/vue'
import Section, { SectionOptions } from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { CmsLabel, CmsText,CmsContent, CmsBoolean, CmsEnum } from '@fwk-client/modules/cms/types';
import { languagesTypes } from '@fwk-client/store/types';
import { useCart } from '@root/src/client/composables/useCart'


/** @cmsOptions */
export interface CartOptions {
    /** @cmsType CmsEnum */
    type?:CmsEnum<"shop"|"hospitality">
}

/** @cmsLabels */
export interface CartLabels {
  /** @cmsType CmsLabel */
  subTotal?:CmsLabel;
}

/** @cmsSlots */
export interface CartSlots {
    
}

export default defineComponent({
    props: {
        options: Object as PropType<CartOptions>,
        labels: {
          type: Object as PropType<CartLabels>,
          default: () => { return {} }
        },
        components: Object as PropType<CartSlots>
    },
    components: {
        Products : () => import(/* webpackChunkName: "panels-checkout-cart-products" */ '../container/Products.vue'),
        Activities : () => import(/* webpackChunkName: "panels-checkout-cart-activities" */ '../container/Activities.vue'),
        Rentals : () => import(/* webpackChunkName: "panels-checkout-cart-rentals" */ '../../hospitality/container/Rentals.vue'),
        Validation : () => import(/* webpackChunkName: "panels-checkout-cart-validation" */ '../../hospitality/container/Validation.vue'),
    },
    setup(props, context) {
        const app = getApp();
        const $store = useStore();

        const computedOptions:CartOptions = {
            type : "shop",
            ...props.options,
        };

        const { onBackToListClick, onCheckoutClick, cartProducts, cartActivities, subTotal } = useCart({
            type: computedOptions.type!
        }, context);

        const labels:CartLabels = {
            subTotal: computedOptions.type == "shop" ? app.$t("shop.checkout.review-cart.sub-total") : app.$t("hospitality.checkout.review-cart.sub-total"),
            ...props.labels
        }

        const currentLanguageCode =  $store.getters['languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE];

        return {
            labels,
            currentLanguageCode,
            cartProducts,
            cartActivities,
            subTotal,
            computedOptions: {
              ...computedOptions
            }
        }

    }
})

</script>